// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.



import * as ActiveStorage from "@rails/activestorage"

ActiveStorage.start() 



import 'alpinejs'
import "chartkick/chart.js"


const images = require.context('../images', true)

const imagePath = (name) => images(name, true)

// Start StimulusJS
import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

import "@hotwired/turbo-rails"

import "controllers"

import { Controller } from "@hotwired/stimulus"

const application = Application.start();

// Import and register all TailwindCSS Components
import { Alert, Autosave, Dropdown, Modal, Tabs, Popover, Toggle, Slideover } from "tailwindcss-stimulus-components"
application.register('alert', Alert)
application.register('autosave', Autosave)
application.register('dropdown', Dropdown)
application.register('modal', Modal)
application.register('tabs', Tabs)
application.register('popover', Popover)
application.register('toggle', Toggle)
application.register('slideover', Slideover)


//import "stylesheets/application"

import "stylesheets/application"

document.addEventListener("turbo:frame-load", function() {
    initLabelAnimation();
    
})

document.addEventListener("turbo:load", function() {
    initLabelAnimation();
    
})



function initLabelAnimation() {
    const allInputs = document.querySelectorAll('input');
    for(const input of allInputs) {
        let val = input.value
        if(!val) {
            input.parentElement.classList.add('empty')
        } else {
            input.parentElement.classList.remove('empty')
        }
        input.addEventListener('input', () => {
            const val = input.value
            if(!val) {
                input.parentElement.classList.add('empty')
            } else {
                input.parentElement.classList.remove('empty')
            }
        })
    }
}



